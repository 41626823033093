import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"

export const Main = styled.div`
  padding: 16px 0px 32px;
  p {
    line-height: 1.32em;
    margin-bottom: 1.62em;
    padding: 0 16px;
  }
  ${() =>
    media.tablet(css`
      max-width: 800px;
      margin: 0 auto;
    `)}
`

export const ThirdLayer = styled.div`
  width: 100%;
  height: 85vh;
  position: fixed;
  overflow-y: scroll;
`
